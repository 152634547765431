/**
  * Utilities
  */

/* Text */
.lede {
    font-size: 1.1em;
    font-weight: 300;
}

.text {
    &--primary {
        color: $primary;
        color: var(--primary);
    }

    &--secondary {
        color: $secondary;
        color: var(--secondary);
    }

    &--info {
        color: $info;
        color: var(--info);
    }

    &--success {
        color: $green;
        color: var(--green);
    }

    &--warning {
        color: $warning;
        color: var(--warn);
    }

    &--danger {
        color: $red;
        color: var(--red);
    }

    &--muted {
        color: $grey-700;
        color: var(--grey700);
    }

    &--small {
        /* consistent with <small> */
        font-size: 0.8em;
    }

    &--large {
        font-size: 2em;
    }

    &--normal {
        font-weight: normal;
    }

    &--bold {
        font-weight: 600; // not quite font-weight: bold == 700
    }

    &--light {
        font-weight: 300;
    }

    &--upper {
        text-transform: uppercase;
    }
}

/* https://a11yproject.com/posts/how-to-hide-content/ */
.visually-hidden {
    position: absolute !important;
    height: 1px;
    width: 1px;
    overflow: hidden;
    clip: rect(1px, 1px, 1px, 1px);

    a:focus,
    input:focus,
    button:focus {
        position: static;
        width: auto;
        height: auto;
    }
}
