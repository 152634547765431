.list {
    &--none {
        list-style: none;
        margin-left: 0;
    }

    &--alpha {
        list-style-type: lower-alpha;
    }

    &--roman {
        list-style-type: lower-roman;
    }

    &--circle {
        list-style-type: circle;
    }

    &--disc {
        list-style-type: disc;
    }

    &--square {
        list-style-type: square;
    }
}
