.img {
    &--default {
        background-color: $white;
        background-color: var(--white);
        border: 1px solid $grey-100;
        border-color: var(--grey100);
        border-radius: 0.25rem;
        padding: 0.25rem;
    }

    &--round {
        border-radius: 50%;
        object-fit: contain;
    }

    &--rounded {
        border-radius: 0.25rem;
    }

    &--padded {
        background-color: $white;
        background-color: var(--white);
        padding: 0.25rem;
    }

    &--border {
        border: 1px solid $grey-100;
        border-color: var(--grey100);
    }
}

/* Apply a filter directly on the body tag */
body.theme--dark img {
    filter: brightness(.8) contrast(1.2);
}

/* Or apply it via media query */
@media (prefers-color-scheme: dark) {
    img {
        filter: brightness(.8) contrast(1.2);

        .theme--light & {
            filter: none;
        }
    }
}
