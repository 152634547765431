/**
  * Cards
  */

.card {
    $self: &;
    background-color: $white;
    background-color: var(--white);
    border: 1px solid $grey-400;
    border-color: $grey-400;
    border-color: var(--grey400);
    border-radius: 0.25rem;
    overflow: hidden;

    &--primary {
        border-color: $primary;
        border-color: var(--primary);

        #{ $self }__title {
            background-color: $white;
            background-color: var(--white);
            border-color: $primary;
            border-color: var(--primary);
            color: $primary;
            color: var(--primary);
        }

        &-dark {
            border-color: $primaryDark;
            border-color: var(--primaryDark);

            #{ $self }__title {
                background-color: $primary;
                border-color: $primaryDark;
                border-color: var(--primaryDark);
                color: $white;
            }
        }
    }

    &--secondary {
        border-color: $secondary;
        border-color: var(--secondary);

        #{ $self }__title {
            background-color: $white;
            background-color: var(--white);
            border-color: $secondary;
            border-color: var(--secondary);
            color: $secondary;
            color: var(--secondary);
        }

        &-dark {
            border-color: $secondaryDark;
            border-color: var(--secondaryDark);

            #{ $self }__title {
                background-color: $secondary;
                border-color: $secondaryDark;
                border-color: var(--secondaryDark);
                color: $white;
            }
        }
    }

    &--info {
        border-color: $info;
        border-color: var(--info);

        #{ $self }__title {
            background-color: $white;
            background-color: var(--white);
            border-color: $info;
            border-color: var(--info);
            color: $info;
            color: var(--info);
        }

        &-dark {
            border-color: $infoDark;
            border-color: var(--infoDark);

            #{ $self }__title {
                background-color: $info;
                border-color: $infoDark;
                border-color: var(--infoDark);
                color: $white;
            }
        }
    }

    &--success {
        border-color: $green;
        border-color: var(--green);

        #{ $self }__title {
            background-color: $white;
            background-color: var(--white);
            border-color: $green;
            border-color: var(--green);
            color: $green;
            color: var(--green);
        }

        &-dark {
            border-color: $successDark;
            border-color: var(--successDark);

            #{ $self }__title {
                background-color: $green;
                border-color: $successDark;
                border-color: var(--successDark);
                color: $white;
            }
        }
    }

    &--warning {
        border-color: $warning;
        border-color: var(--warn);

        #{ $self }__title {
            background-color: $white;
            background-color: var(--white);
            border-color: $warning;
            border-color: var(--warn);
            color: $warning;
            color: var(--warn);
        }

        &-dark {
            border-color: $warningDark;
            border-color: var(--warningDark);

            #{ $self }__title {
                background-color: $warning;
                border-color: $warningDark;
                border-color: var(--warningDark);
                color: $white;
            }
        }
    }

    &--danger {
        border-color: $red;
        border-color: var(--red);

        #{ $self }__title {
            background-color: $white;
            background-color: var(--white);
            border-color: $red;
            border-color: var(--red);
            color: $red;
            color: var(--red);
        }

        &-dark {
            border-color: $dangerDark;
            border-color: var(--dangerDark);

            #{ $self }__title {
                background-color: $red;
                border-color: $dangerDark;
                border-color: var(--dangerDark);
                color: $white;
            }
        }
    }

    &__title {
        background-color: $silver;
        background-color: var(--silver);
        border-bottom: 1px solid;
        border-bottom-color: $grey-400;
        border-bottom-color: var(--grey400);
        padding: 1rem;

        &>* {
            font-size: 1.2rem;
            margin: 0;
        }
    }

    &__body {
        padding: 1rem;

        &>* {
            margin-bottom: 1em;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}
