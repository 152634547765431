@use 'sass:math';

/**
  * Forms
  */

.form {

    input[type=radio]:focus,
    input[type=checkbox]:focus {
        box-shadow: 0 0 0 0.2rem rgba($primary, 0.5);
        box-shadow: 0 0 0 0.2rem var(--focusShadow);
        outline: none;
    }

    &--inline {
        display: flex;
        margin: 0 0.2rem;

        & input,
        & select {
            width: auto;
        }

        & .form__label,
        & .form__control {
            display: inline-block;
        }

        &>.form__group {
            margin-bottom: 0;
            margin-right: 4px;
            margin-top: 4px;

            &>.button {
                margin-top: 0;
            }
        }
    }

    &__group {
        margin-bottom: 1rem;
    }

    &__label {
        display: block;
        padding-bottom: math.div($spacing, 2);
    }

    &__control {
        background-color: $white;
        background-color: var(--white);
        background-clip: padding-box;
        border: 1px solid;
        border-color: $grey-600;
        border-color: var(--grey600);
        border-radius: 0.25em;
        color: $black;
        color: var(--black);
        display: block;
        /* stolen from getbootstrap.com
           corrects <select> size */
        height: calc(1.5em + .75rem + 2px);
        margin-bottom: math.div($spacing, 4);
        outline: none;
        padding: 0.5em;
        width: 100%;

        @at-root #{selector-append(select, &)} {
            padding: 0.25em 0.25em;
        }

        &:focus {
            box-shadow: 0 0 0 0.2rem rgba($primary, 0.5);
            box-shadow: 0 0 0 0.2rem var(--focusShadow);
        }

        &--file {
            background-color: transparent;
            border: none;
            height: auto;
            padding: calc(0.5em + 1px);
            /* account for removed border */
            padding-left: 0;
        }

        &-error {
            color: $red;
            color: var(--red);
            margin-bottom: math.div($spacing, 4);
        }

        &--hasError {
            border-color: $red;
            border-color: var(--red);

            &:focus {
                box-shadow: 0 0 0 0.2rem rgba($red, 0.5);
                box-shadow: 0 0 0 0.2rem var(--errorShadow);
            }
        }
    }

    &__check {
        margin-bottom: math.div($spacing, 2);

        input[type=checkbox],
        input[type=radio] {
            margin-right: math.div($spacing, 4);
            /* spacing between control and text */
        }
    }
}

.input {
    &--large {
        font-size: 1.25em;
    }
}

fieldset.form__group {
    border: none;
    padding: 0;
}
