@use 'sass:math';

/**
  * Base elements
  */

/* apply a natural box layout model to all elements, but allowing components to change */
/* https://www.paulirish.com/2012/box-sizing-border-box-ftw/ */
html {
    box-sizing: border-box;
    overflow-x: hidden;
}

*,
*:before,
*:after {
    box-sizing: inherit;
}

* {
    margin: 0;
    padding: 0;
}

body {
    background-color: $white;
    background-color: var(--bodyBg);
    color: $black;
    color: var(--black);
    font-family: $system-stack;
    font-variant-ligatures: common-ligatures;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: 600;
}

h2,
h3,
h4,
h5,
h6 {
    margin-top: 0.67em;
    margin-bottom: 0.67em;
}

.subheading {
    color: $grey-700;
    color: var(--grey700);

    h1 &,
    h2 & {
        font-size: 0.8em;
    }

    h3 &,
    h4 & {
        font-size: 0.9em;
    }
}

p {
    line-height: 1.4;
}

p+p,
p+div {
    margin-top: $spacing;
}

a {
    color: $primary;
    color: var(--primary);

    &:hover,
    &:active {
        color: darken($primary, 12%);
        color: var(--primaryActive);
    }

    &:visited {
        color: lighten($primary, 8%);
        color: var(--primaryVisited);
    }

    &:focus {
        border-radius: 0.25em;
        box-shadow: 0 0 0 0.2rem rgba($primary, 0.5);
        box-shadow: 0 0 0 0.2rem var(--focusShadow);
        outline: none;
    }

    &.permanent {

        &:hover,
        &:active,
        &:visited {
            color: $primary;
            color: var(--primary);
        }
    }

    h1>&,
    h2>&,
    h3>&,
    h4>&,
    h5>&,
    h6>& {
        color: inherit;
        text-decoration: none;

        &:hover,
        &:active {
            color: darken($primary, 12%);
            color: var(--primaryActive);
            text-decoration: underline;
        }

        &:visited {
            color: inherit;
        }
    }
}

img {
    max-width: 100%;
}


// Lists

ul,
ol {
    margin-bottom: 1em;
    margin-left: 1.1em;
}

ul ul,
ul ol,
ol ol,
ol ul {
    margin-bottom: 0.5em;
    margin-left: 2em;
}

li {
    line-height: 1.3em;
    margin-bottom: 0.3em;
}

dt {
    font-weight: bold;
}

dt,
dd {
    line-height: 1.5;
}

dd {
    margin-bottom: 0.25em;
}


// Presentation

blockquote {
    margin-top: 0.5em;
    margin-bottom: 0.5em;
}

code {
    color: $pink;
    color: var(--pink);
    display: inline-block;
    font-family: monospace;
    padding: 0 2px;
    word-break: break-word;
}

pre {
    background-color: $silver;
    background-color: var(--silver);
    padding: math.div($spacing, 2);
    white-space: pre-wrap;
    width: 100%;

    code {
        color: $grey-800;
        color: var(--grey800);
    }
}
