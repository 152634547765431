@mixin layout-xs {
    @media (max-width: ($breakpoint-sm - 1)) {
        @content;
    }
}

@mixin layout-sm {
    @media (min-width: #{$breakpoint-sm}) {
        @content;
    }
}

@mixin layout-md {
    @media (min-width: #{$breakpoint-md}) {
        @content;
    }
}

@mixin layout-lg {
    @media (min-width: #{$breakpoint-lg}) {
        @content;
    }
}

@mixin layout-xl {
    @media (min-width: #{$breakpoint-xl}) {
        @content;
    }
}
