/**
 * Fonts
 */

@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@600&display=swap');
$raleway-stack: Raleway,
Verdana,
"Lucida Grande",
"Gill Sans",
Arial,
Helvetica,
sans-serif;
$system-stack: -apple-system,
BlinkMacSystemFont,
"Segoe UI",
Roboto,
Helvetica,
Arial,
sans-serif,
"Apple Color Emoji",
"Segoe UI Emoji",
"Segoe UI Symbol" !default;
