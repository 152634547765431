/**
  * Colours
  */
$white: #fff;
$black: #222;
$fullBlack: #000;
$pink: #e83e8c;
$primary: #005b99;
/* https://www.canada.ca/en/treasury-board-secretariat/services/government-communications/federal-identity-program/technical-specifications/official-symbols/colour-values.html */
$primaryDark: darken($primary, 20%);
$secondary: #993d00;
/* 180deg from primary*/
$secondaryDark: darken($secondary, 20%);
$green: #036803;
$successDark: darken($green, 20%);
$info: #03607d;
$infoDark: darken($info, 20%);
$red: #b60202;
$dangerDark: darken($red, 20%);
$yellow: #f9d615;
$warning: #695802;
$warningDark: darken($warning, 20%);
$linkColor: $primary;

$primaryDarkMode: #4cb5fa;
$secondaryDarkMode: #fa924c;
$greenDarkMode: #09c809;
$infoDarkMode: #0bbaef;
$redDarkMode: #f49090;
$warnDarkMode: #f9d615;
$pinkDarkMode: #f28cbc;

$primaryActive: darken($primary, 12%);
$primaryVisited: lighten($primary, 8%);

$primaryDarkModeActive: darken($primaryDarkMode, 12%);
$primaryDarkModeVisited: lighten($primaryDarkMode, 8%);

/* Greys */
$grey-900: #363640;
$grey-800: #4a4a4e;
$grey-700: #5e5e62;
$grey-600: #727276;
$grey-500: #868690;
$grey-400: #9b9b9f;
$grey-300: #afafb3;
$grey-200: #c3c3c7;
$grey-100: #d7d7db;
$silver: #ebebef;

/**
  * Spacing
  */
$spacing: 1rem;

/**
  * Breakpoints
  * https://primer.style/css/support/breakpoints
  */
$breakpoint-sm: 544px;
$breakpoint-md: 768px;
$breakpoint-lg: 1012px;
$breakpoint-xl: 1280px;
