/* Dependencies
 *
 * Normalize 8.0.1 - github.com/necolas/normalize.css
 */

// Core variables and mixins
@import "scss/variables";
@import "scss/mixins";

// Reset and dependencies
@import "scss/normalize";
@import "scss/fonts";

// Core
@import "scss/root";
@import "scss/base";

// Layouts
@import "scss/layouts/header";
@import "scss/layouts/main";
@import "scss/layouts/sections";
@import "scss/layouts/toolbar";

// Components
@import "scss/alerts";
@import "scss/buttons";
@import "scss/cards";
@import "scss/forms";
@import "scss/icons";
@import "scss/images";
@import "scss/lists";
@import "scss/navigation";

// Utilities
@import "scss/utilities/flex";
@import "scss/utilities/spacing";
@import "scss/utilities/text";
